import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/ghadiya-admin/services/auth.service';

import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-high-position',
  templateUrl: './high-position.component.html',
  styleUrls: ['./high-position.component.scss']
})
export class HighPositionComponent implements OnInit {

  highPositionForm: FormGroup;
  highPosition: any = [];
  Loading: boolean = false;

  searchQsn: any;
  searchResult: any = [];
  constructor(private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService) { 
    
    this.highPositionForm = fb.group({
      _id: [''],
      Name: ['',Validators.required],
      Village: ['',Validators.required],
      Taluka: ['',Validators.required],
      Distict: ['',Validators.required],
      Address: ['',Validators.required],
      City: ['',Validators.required],
      Position: ['',Validators.required],
      Mobile: ['',Validators.required],    
    });

  }
  


  ngOnInit(): void {
    this.getHighPDetail();  
  }



  changeFn(event) {
    this.Loading = true;
   let body = {
      'status': event.target.value
    }
    this.authService.filterHighPos(body).then((res) => {
      
      setTimeout(() => {
        this.Loading = false;
        this.highPosition = [];
        this.highPosition = res;
      }, 1000);
    }, err => console.log("err", err));

  }


  onChange(ev, _id) {
    if (ev.target.checked) {
      this.authService.permissionHighPos({
        _id: _id,
        status: true
      }).then((res) => {
        // console.log("true",res);
        this.toastr.success('User Activate Successfull');
      }, err => console.log("err", err));
    } else {
      this.authService.permissionHighPos({
        _id: _id,
        status: false
      }).then((res) => {
        // console.log("false", res);

        this.toastr.success('User DeActivate Successfull');
      }, err => console.log("err", err));
    }
  }

  
  // search filter start

  serchQsnByName() {
    this.highPosition = this.searchResult;
    var val = this.searchQsn.toLowerCase();
    if (val && val.trim() != '') {
      this.highPosition = this.highPosition.filter((item) => {
        return (item.Name.toLowerCase().indexOf(val) > -1) || (item.Village.toLowerCase().indexOf(val) > -1)
      })
    }
    else {
      this.highPosition = this.searchResult;
    }
  }

  // search filter End


  getHighPDetail() {
    this.authService.getHighPDtl().then(
      res => {
        // console.log("this.highPosition", res);
        if (res['status']) {
          this.highPosition = res['res'];
          this.searchResult = this.highPosition;
        } else {
          // console.log("status fales");
        }

      },
      err => {
        console.log(err);
      },
    );
  }

  submitData() {
    if (this.highPositionForm.value._id) {
      this.authService.editHighPDtl(this.highPositionForm.value).then((res) => {
        this.getHighPDetail();
        this.highPositionForm.reset();
        this.toastr.success(res['msg']);
        $('#myModal').modal('hide');
      }, err => console.log("err", err));

    } else {
      // console.log(this.highPositionForm.value)
      if (this.highPositionForm.valid) {
        this.authService.saveHighPDtl(this.highPositionForm.value).then((res) => {
          this.getHighPDetail();
          // console.log("asdddddddd",res)
          this.highPositionForm.reset();
          this.toastr.success(res['msg']);
          $('#myModal').modal('hide');
        }, err => console.log("err", err));
      } else {
        this.authService.validationFire(this.highPositionForm);
        console.log("fire")
      }
     
    }

  }

  openModal(item) {

    // console.log("itemitemitem", item);
    if (item._id) {
      this.highPositionForm.patchValue({
        _id: item._id,
        Name: item.Name,
        Village: item.Village,
        Taluka: item.Taluka,
        Distict: item.Distict,
        Address: item.Address,
        City: item.City,
        Position: item.Position,
        Mobile: item.Mobile
      });

    } else {
      // console.log("Add");
      this.highPositionForm.reset();
    }
  }

  DeleteData(_id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.authService.deleteHighPDtl({ _id: _id }).then(
          (res: any) => {
            // console.log("delete", res)
            this.getHighPDetail();
            this.highPositionForm.reset();
            this.toastr.success(res['msg']);

            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )


          },
          err => {
            console.log(err);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })

  }
}
