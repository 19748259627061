<div class="mein-container">
    <!-- get Data Table -->
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0 pb-0">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="mb-0">BUSINESS DETAILS </h3>
                            </div>
                        </div>
                    </div>
                    <form autocomplete="off" class="p-3">
                        <div class="row">
                            <div class="col-md-4 col-xl-4 pb-1">
                                <input class="form-control form-control-sm" type="text" placeholder="Search"
                                    name="searchQsn" [(ngModel)]="searchQsn"  (keyup)="serchQsnByName()" aria-label="Search">
                            </div>
                            <div class="col-md-4 col-xl-4 pb-1">
                                <select class="form-control form-control-sm" (change)="changeFn($event,'status')">
                                    <option value="">
                                        All
                                    </option>
                                    <option value="1">
                                        Active
                                    </option>
                                    <option value="0" [selected]="true">
                                        DeActive
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 col-xl-4">
                                <select class="form-control form-control-sm" (change)="changeFn($event,'village')">
                                    <option value="" [selected]="true">
                                        -- Select Village
                                    </option>
                                    <option value="{{item.busCity}}" *ngFor="let item of cityFilterData">
                                        {{item.busCity}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <div class="loading" *ngIf="Loading">
                        <img alt="Image placeholder" src="assets/loder/loder.gif">
                    </div>
                    <div class="table-responsive" *ngIf="businessData.length">
                        <!-- Projects table -->
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Active/Deactive</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of businessData;let i = index">
                                    <th scope="row">
                                        {{i + 1}}
                                    </th>
                                    <th>
                                        {{item.busName}}
                                    </th>
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="{{item._id}}"
                                                value="{{item.status}}" [(ngModel)]="item.status"
                                                (change)="onChange($event,item._id)">
                                            <label class="custom-control-label"
                                                for="{{item._id}}">Active/Deactive</label>
                                        </div>
                                    </td>

                                    <td>
                                        <button type="button" class="btn btn-sm btn-warning mr-3" data-toggle="modal"
                                            data-target="#myModal" (click)="openModal(item)">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-danger btnSmallDevice"
                                            (click)="DeleteData(item._id)">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div *ngIf="!businessData.length && !Loading">
                    <div class="font-weight-bold text-center">
                        No Data Found
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Data Modal Popop -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header pb-0">
                    <h4 class="modal-title">Business Details</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <form [formGroup]="businessForm">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-city">વ્યવસાય નું નામ</label>
                                    <input type="text" class="form-control" formControlName="busName"
                                        placeholder="વ્યવસાય નું નામ">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">વ્યવસાય નો પ્રકાર</label>
                                    <input type="text" class="form-control" formControlName="busType"
                                        placeholder="વ્યવસાય નો પ્રકાર">
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">માલિક નું નામ</label>
                                    <input type="text" class="form-control" formControlName="busOwner"
                                        placeholder="માલિક નું નામ">
                                </div>
                            </div>
                          
                        </div>

                        <div class="row">

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">ફોન નંબર</label>
                                    <input type="text" class="form-control" formControlName="busContact"
                                        placeholder="ફોન નંબર">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">સરનામું</label>
                                    <input type="text" class="form-control" formControlName="busAddress"
                                        placeholder="સરનામું">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">શહેર</label>
                                    <input type="text" class="form-control" formControlName="busCity"
                                        placeholder="શહેર">
                                </div>
                            </div>                           

                        </div>
                        <div class="row">

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">વ્યવસાય નો પ્રકાર</label>
                                    <select class="form-control" formControlName="busCategory" required="true">
                                        <option value="" [selected]="true">
                                            -- Select Paid --
                                        </option>
                                        <option value="ડોક્ટર">ડોક્ટર</option>
                                        <option value="વકીલ">વકીલ</option>
                                        <option value="સોફ્ટવેર એન્જીનીયર">સોફ્ટવેર એન્જીનીયર</option>
                                        <option value="એસ્ટેટ એજન્ટ">એસ્ટેટ એજન્ટ</option>
                                        <option value="કોન્ટ્રાક્ટર">કોન્ટ્રાક્ટર</option>
                                        <option value="સિવિલ એન્જીનીયર">સિવિલ એન્જીનીયર</option>
                                        <option value="લેબોરેટરી">લેબોરેટરી</option>
                                        <option value="ઇલેક્ટ્રોનિક">ઇલેક્ટ્રોનિક</option>
                                        <option value="એકાઉન્ટન્ટ">એકાઉન્ટન્ટ</option>
                                        <option value="એગ્રો">એગ્રો</option>
                                        <option value="આર્કિટેકટ્સ">આર્કિટેકટ્સ</option>
                                        <option value="હાર્ડવેર">હાર્ડવેર</option>
                                        <option value="ઓટોપાર્ટ્સ">ઓટોપાર્ટ્સ</option>
                                        <option value="મોબાઈલ">મોબાઈલ</option>
                                        <option value="અટોમોબાઇલ">અટોમોબાઇલ</option>
                                        <option value="બેકરી">બેકરી</option>
                                        <option value="ફર્નિચર">ફર્નિચર</option>
                                        <option value="ફેબ્રિકેસન">ફેબ્રિકેસન</option>
                                        <option value="બ્રાસપાર્ટ">બ્રાસપાર્ટ</option>
                                        <option value="ફોટોગ્રાફર">ફોટોગ્રાફર</option>
                                        <option value="બ્યુટીપાલર">બ્યુટીપાલર</option>
                                        <option value="કેટરર્સ">કેટરર્સ</option>
                                        <option value="મંડપ સર્વિસ">મંડપ સર્વિસ</option>
                                        <option value="સુથારકામ">સુથારકામ</option>
                                        <option value="સીસીટીવી કેમેરા">સીસીટીવી કેમેરા</option>
                                        <option value="સીરામીક">સીરામીક</option>
                                        <option value="જવેલર્સ">જવેલર્સ</option>
                                        <option value="ડાઈમંડ">ડાઈમંડ</option>
                                        <option value="જીમ">જીમ</option>
                                        <option value="કારીયાના સ્ટોર">કારીયાના સ્ટોર</option>
                                        <option value="ગ્રાફિક્સ">ગ્રાફિક્સ</option>
                                        <option value="અન્ય">અન્ય</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-address">અન્ય</label>
                                    <input class="form-control" formControlName="busOther" placeholder="અન્ય"
                                        type="text">
                                </div>
                            </div>
                            <!--<div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-address">Business Job</label>
                                    <input class="form-control" formControlName="busJob" placeholder="Business Job"
                                        type="text">
                                </div>
                            </div> -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-address">વધુ માહિતી</label>
                                    <input class="form-control" formControlName="busComment"
                                        placeholder="વધુ માહિતી" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="float-right">
                            <button type="button" class="btn btn-primary" (click)="submitData()">Save</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

</div>