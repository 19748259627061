import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/ghadiya-admin/services/auth.service';

import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-change-version',
  templateUrl: './change-version.component.html',
  styleUrls: ['./change-version.component.scss']
})
export class ChangeVersionComponent implements OnInit {

  Loading: boolean = false;
  changeVForm: FormGroup;
  changeVersion: any = [];

  searchQsn: any;
  searchResult: any = [];
  constructor(private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService) {

    this.changeVForm = fb.group({
      _id: [''],
      name: ['', Validators.required],
      flag: ['', Validators.required],
    });


  }

  ngOnInit(): void {
    this.getVersionDtl();
  }
  // VersionDtl Section Statrt


  // search filter section start

  serchQsnByName() {
    this.changeVersion = this.searchResult;
    var val = this.searchQsn.toLowerCase();
    if (val && val.trim() != '') {
      this.changeVersion = this.changeVersion.filter((item) => {
        return (item.name.toLowerCase().indexOf(val) > -1)
      })
    }
    else {
      this.changeVersion = this.searchResult;
    }
  }
  // search filter section End
  getVersionDtl() {
    this.authService.getVersion().then(
      res => {
        // console.log(res)
        if (res['status']) {
          this.changeVersion = res['res'];
          this.searchResult = this.changeVersion;
          // console.log("changeVersion", this.changeVersion);
        } else {
          // console.log("status fales");
        }

      },
      err => {
        console.log(err);
      },
    );
  }

  openVersionModal(item) {
    if (item._id) {
      this.changeVForm.patchValue({
        _id: item._id,
        name: item.name,
        flag: item.flag
      });

    } else {
      // console.log("Add");
      this.changeVForm.reset();
    }
  }

  submitVersion() {
    if (this.changeVForm.value._id) {
      this.authService.editVersion(this.changeVForm.value).then((res) => {
        // console.log("editdataaaaa",res)
        this.getVersionDtl();
        this.changeVForm.reset();
        this.toastr.success(res['msg']);
        $('#myModal').modal('hide');
      }, err => console.log("err", err));

    } else {
      console.log("error");
    }

  }


  // VersionDtl Section End
}
