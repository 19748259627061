<nav class="navbar navbar-expand-md bg-dark navbar-dark fixed-top">
    <div class="container">
        <!-- Brand -->
        <a class="navbar-brand">
            KHODIYAR ESTATE
        </a>
        <!-- Toggler/collapsibe Button -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link" (click)="homeSlider()" routerLink="/home" routerLinkActive="active">HOME</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="homeConsultant()" routerLinkActive="active">SERVICES</a>
                </li>
            </ul>
        </div>
    </div>
</nav>