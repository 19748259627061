import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ghadiya-admin',
  templateUrl: './ghadiya-admin.component.html',
  styleUrls: ['./ghadiya-admin.component.scss']
})
export class GhadiyaAdminComponent implements OnInit {

  constructor(private router: Router) {
 

  }


  ngOnInit(): void {
  }

}
