<div class="mein-container">
    <!-- get Data Table -->
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0 pb-0">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="mb-0">HIGH POSITION</h3>
                            </div>
                            <div class="col text-right">
                                <a href="#!" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal"
                                    (click)="openModal('')">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <form autocomplete="off" class="p-3 pt-0">
                        <div class="row">
                            <div class="col-md-6 col-xl-6 pb-1">
                                <input class="form-control form-control-sm" type="text" placeholder="Search" name="searchQsn" [(ngModel)]="searchQsn"
                                    (keyup)="serchQsnByName()" aria-label="Search">
                            </div>
                            <div class="col-md-6 col-xl-6">
                                <select class="form-control form-control-sm" (change)="changeFn($event)">
                                    <option value="">
                                        All
                                    </option>
                                    <option value="1" [selected]="true">
                                        Active
                                    </option>
                                    <option value="0">
                                        DeActive
                                    </option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <div class="loading" *ngIf="Loading">
                        <img alt="Image placeholder" src="assets/loder/loder.gif">
                    </div>
                    <div class="table-responsive" *ngIf="highPosition.length">
                        <!-- Projects table -->
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Active/Deactive</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of highPosition;let i=index">
                                    <td scope="row">
                                        {{i + 1}}
                                    </td>
                                    <td>
                                        {{item.Name}}
                                    </td>
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="{{item._id}}" value="{{item.status}}"
                                                [(ngModel)]="item.status" (change)="onChange($event,item._id)">
                                            <label class="custom-control-label" for="{{item._id}}">Active/Deactive</label>
                                        </div>
                                    </td>

                                    <td>
                                        <button type="button" class="btn btn-sm btn-warning mr-3" data-toggle="modal"
                                            data-target="#myModal" (click)="openModal(item)">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-danger btnSmallDevice"
                                            (click)="DeleteData(item._id)">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="!highPosition.length && !Loading">
                    <div class="font-weight-bold text-center">
                        No Data Found
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Add Data Modal Popop -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header pb-0">
                    <h4 class="modal-title">HIGH POSITION</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <form [formGroup]="highPositionForm">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-city">નામ <span class="text-danger"> * </span></label>
                                    <input type="text" class="form-control"  formControlName="Name" placeholder="નામ"
                                     [ngClass]="{'has-error' : highPositionForm.get('Name').errors && ( highPositionForm.get('Name').dirty || highPositionForm.get('Name').touched) }">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">ગામ નું નામ<span
                                            class="text-danger"> * </span></label>
                                    <input type="text" class="form-control"  formControlName="Village"
                                        placeholder="ગામ નું નામ"
                                        [ngClass]="{'has-error' : highPositionForm.get('Village').errors && ( highPositionForm.get('Village').dirty || highPositionForm.get('Village').touched) }">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">તાલુકો<span
                                            class="text-danger"> * </span></label>
                                    <input type="text" class="form-control"  formControlName="Taluka"
                                        placeholder="તાલુકો"
                                        [ngClass]="{'has-error' : highPositionForm.get('Taluka').errors && ( highPositionForm.get('Taluka').dirty || highPositionForm.get('Taluka').touched) }">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">શહેર<span class="text-danger"> * </span></label>
                                    <input type="text" class="form-control"  formControlName="City" placeholder="શહેર"
                                        [ngClass]="{'has-error' : highPositionForm.get('City').errors && ( highPositionForm.get('City').dirty || highPositionForm.get('City').touched) }">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">જીલ્લો <span class="text-danger"> * </span></label>
                                    <input type="text" class="form-control"  formControlName="Distict"
                                        placeholder="જીલ્લો"
                                        [ngClass]="{'has-error' : highPositionForm.get('Distict').errors && ( highPositionForm.get('Distict').dirty || highPositionForm.get('Distict').touched) }">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">હોદો <span class="text-danger"> * </span></label>
                                    <input type="text" class="form-control"  formControlName="Position" placeholder="હોદો"
                                    [ngClass]="{'has-error' : highPositionForm.get('Position').errors && ( highPositionForm.get('Position').dirty || highPositionForm.get('Position').touched) }" >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">ફોન નંબર <span class="text-danger"> * </span></label>
                                    <input type="text" class="form-control" formControlName="Mobile" placeholder="ફોન નંબર"
                                    [ngClass]="{'has-error' : highPositionForm.get('Mobile').errors && ( highPositionForm.get('Mobile').dirty || highPositionForm.get('Mobile').touched) }" >
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">સરનામું<span class="text-danger"> * </span></label>
                                    <input type="text" class="form-control" formControlName="Address" placeholder="સરનામું"
                                        [ngClass]="{'has-error' : highPositionForm.get('Address').errors && ( highPositionForm.get('Address').dirty || highPositionForm.get('Address').touched) }">
                                </div>
                            </div>
                        </div>
                        <div class="float-right">
                            <button type="button" class="btn btn-primary" (click)="submitData()">Save</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

</div>