<div class="mein-container">

    <!-- Advance Salary Sectio Start -->
    <div class="container-fluid ">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0 pb-0">
                        <div class="row align-items-center pb-0">
                            <div class="col">
                                <h3 class="mb-0">VERSION</h3>
                            </div>
                        </div>
                    </div>
                    <form autocomplete="off" class="p-3">
                        <div class="row col-md-6 col-xl-6">
                            <input class="form-control form-control-sm" type="text" placeholder="Search" name="searchQsn" [(ngModel)]="searchQsn"
                                (keyup)="serchQsnByName()" aria-label="Search">
                        </div>
                    </form>
                    <div class="table-responsive" *ngIf="changeVersion.length">
                        <!-- Projects table -->
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Version</th>
                                    <th scope="col">Flag</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of changeVersion; let i = index">
                                    <th scope="row">
                                        {{i + 1}}
                                    </th>
                                    <th>
                                        {{item.name}}
                                    </th>
                                    <td>
                                        {{item.flag}}
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-sm btn-warning" data-toggle="modal"
                                            data-target="#myModal" (click)="openVersionModal(item)">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="!changeVersion.length && !Loading">
                    <div class="font-weight-bold text-center">
                        No Data Found
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Edit Modal Popop -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header pb-0">
                    <h4 class="modal-title">Add Version</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <form [formGroup]="changeVForm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-city">Version <span
                                            class="text-danger"> * </span></label>
                                    <input type="text" class="form-control" formControlName="name"
                                        placeholder="Version"
                                        [ngClass]="{'has-error' : changeVForm.get('name').errors && ( changeVForm.get('name').dirty || changeVForm.get('name').touched) }">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">Flag <span
                                            class="text-danger"> * </span></label>
                                    <input type="text" class="form-control" formControlName="flag"
                                        placeholder="flag"
                                        [ngClass]="{'has-error' : changeVForm.get('flag').errors && ( changeVForm.get('flag').dirty || changeVForm.get('flag').touched) }">
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <button type="button" class="btn btn-primary" (click)="submitVersion()">Save</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

    <!-- Advance Salary Section End -->

</div>