import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, ValidationErrors } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private gs: GlobalService,
    private http: HttpClient) {

  }

// Dashboard section start
  
  getDashboard() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURL + 'get-dashboard-data').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err)
      });
    });
  }
  
// Dashboard section End


  // Business section start
  
  getBusiness() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURL + 'get-gha-business').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }


  permissionBuss(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'permission-gha-business',data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  getCityFilter() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURL + 'city-filter').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  getGhabusinessFilter(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'filter-gha-business', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }
  
  saveBusiness(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'add-gha-business', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }


  editBusiness(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'edit-gha-business', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  deleteBusiness(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'remove-gha-business', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  // ** Business section End ** //


  // Change Version Section Statrt

  getVersion() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURL + 'get-gha-version').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  editVersion(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'edit-gha-version', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

 
  // ** Change Version  Section End ** //


  // HighPosition section start
  getHighPDtl() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURL + 'get-gha-highpos').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  filterHighPos(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'filter-gha-hignpos', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  permissionHighPos(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'permission-hignpos', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  saveHighPDtl(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'add-gha-highpos', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }


  editHighPDtl(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'edit-gha-highpos', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  deleteHighPDtl(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'remove-gha-highpos', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }
  // ** HighPosition section End ** //



  // User Register section start
  getGhaUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURL + 'get-gha-user').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }
  getVillageUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURL + 'village-filter').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  filterUser(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'filter-gha-user', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  permisionUser(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'permission-gha-user', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  saveGhaUser(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'add-gha-user', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }


  editGhaUser(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'edit-gha-user', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  deleteGhaUser(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURL + 'remove-gha-user', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }
  // ** User section End ** //


  // Validation Error

  validationFire(fb: FormGroup) {
    Object.keys(fb.controls).forEach(key => {
      const control = fb.get(key);
      const controlErrors: ValidationErrors = fb.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          control.markAsDirty({ onlySelf: true });
          control.markAsTouched({ onlySelf: true });
        });
      }
    });
  }

  // ***** //

}
