<div class="mein-container">
    <!-- get Data Table -->
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="loading" *ngIf="Loading">
                        <img alt="Image placeholder" src="assets/loder/loder.gif">
                    </div>
                    <div class="card-header border-0 pb-0">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="mb-0">REGISTER USER </h3>
                            </div>
                        </div>
                    </div>
                    <form autocomplete="off" class="p-3">
                        <div class="row">
                            <div class="col-md-4 col-xl-4 pb-1">
                                <input class="form-control form-control-sm" type="text" placeholder="Search" name="searchQsn" [(ngModel)]="searchQsn"
                                    (keyup)="serchQsnByName()" aria-label="Search">
                            </div>
                            <div class="col-md-4 col-xl-4 pb-1">
                                <select class="form-control form-control-sm" (change)="mychange($event,'status')">
                                    <option value="">
                                        All
                                    </option>
                                    <option value="1">
                                        Active
                                    </option>
                                    <option value="0" [selected]="true">
                                        DeActive
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 col-xl-4">
                                <select class="form-control form-control-sm" (change)="mychange($event,'villege')">
                                    <option value="" [selected]="true">
                                        -- Select Village
                                    </option>
                                    <option value="{{villageName.village}}" *ngFor="let villageName of villageDetail">
                                        {{villageName.village}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <div class="table-responsive" *ngIf="userDetail.length">
                        <!-- Projects table -->
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">User Name</th>
                                    <th scope="col">Active/Deactive</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of userDetail;let i = index">
                                    <th scope="row">
                                        {{i + 1}}
                                    </th>
                                    <th>
                                        {{item.firstName}} {{item.lastName}}
                                    </th>
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="{{item._id}}"
                                                value="{{item.status}}" [(ngModel)]="item.status"
                                                (change)="onChange($event,item._id)">
                                            <label class="custom-control-label"
                                                for="{{item._id}}">Active/Deactive</label>
                                        </div>
                                    </td>

                                    <td>
                                        <button type="button" class="btn btn-sm btn-warning mr-3" data-toggle="modal"
                                            data-target="#myModal" (click)="openModal(item)">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-danger btnSmallDevice"
                                            (click)="DeleteData(item._id)">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="!userDetail.length && !Loading">
                    <div class="font-weight-bold text-center">
                        No Data Found
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Add Data Modal Popop -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header pb-0">
                    <h4 class="modal-title">User Details</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <form [formGroup]="userForm">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-city">તમારું નામ</label>
                                    <input type="text" class="form-control" formControlName="firstName"
                                        placeholder="તમારું નામ">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">પિતા / પતિ નું નામ</label>
                                    <input type="text" class="form-control" formControlName="middleName"
                                        placeholder="પિતા / પતિ નું નામ">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">અટક</label>
                                    <input type="text" class="form-control" formControlName="lastName"
                                        placeholder="અટક">
                                </div>
                            </div>
                       
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">ફોન નંબર</label>
                                    <input type="text" class="form-control" formControlName="mobNumber"
                                        placeholder="ફોન નંબર">
                                </div>
                            </div>
                           

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-address">તાલુકો</label>
                                    <input class="form-control" formControlName="taluka" placeholder="તાલુકો"
                                        type="text">
                                </div>
                            </div>
                       

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-address">જીલ્લો</label>
                                    <input class="form-control" formControlName="district" placeholder="જીલ્લો"
                                        type="text">
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">પુરૂષ / સ્ત્રી</label>
                                    <select class="form-control" formControlName="gender" required="true">
                                        <option value="" [selected]="true">
                                            -- Select Paid --
                                        </option>
                                        <option value="પુરૂષ">
                                            પુરૂષ
                                        </option>
                                        <option value="સ્ત્રી">
                                            સ્ત્રી
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">લોહી નું ગૃપ</label>
                                    <select class="form-control" formControlName="bloodGroup" required="true">
                                        <option value="" [selected]="true">
                                            -- Select Paid --
                                        </option>
                                        <option value="O+">
                                            O+
                                        </option>
                                        <option value="O-">
                                            O-
                                        </option>
                                        <option value="A+">
                                            A+
                                        </option>
                                        <option value="A-">
                                            A-
                                        </option>
                                        <option value="B+">
                                            B+
                                        </option>
                                        <option value="B-">
                                            B-
                                        </option>
                                        <option value="AB+">
                                            AB+
                                        </option>
                                        <option value="AB-">
                                            AB-
                                        </option>
                                    </select>
                                </div>
                            </div>
                        
                        <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-country">ગામ નું નામ</label>
                                    <input type="text" class="form-control" formControlName="village"
                                        placeholder="ગામ નું નામ">
                                </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-control-label" for="input-address">હાલ નું શહેર</label>
                                <input formControlName="curCity" class="form-control" placeholder="હાલ નું શહેર"
                                    type="text">
                            </div>
                        </div>
                        </div>
                        <div class="float-right">
                            <button type="button" class="btn btn-primary" (click)="submitData()">Save</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

</div>