import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  getAccount() {
    throw new Error('Method not implemented.');
  }

  apiURL = "https://www.khodiyarestate.com/api/" 
  constructor() { }
}
