
<div class="page-wrapper chiller-theme toggled">
    <a id="show-sidebar" class="btn btn-sm btn-dark ">
        <i class="fa fa-bars"></i>
    </a>
    <nav id="sidebar" class="sidebar-wrapper ">
        <div class="sidebar-content">
            <div class="sidebar-brand">
                <a>Hiren Ghadiya</a>
                <div id="close-sidebar">
                    <i class="fa fa-close "></i>
                </div>
            </div>
            <!-- <div class="sidebar-header">
                <div class="user-pic">
                    <img class="img-responsive img-rounded"
                        src="https://raw.githubusercontent.com/azouaoui-med/pro-sidebar-template/gh-pages/src/img/user.jpg"
                        alt="User picture">
                </div>
                <div class="user-info">
                    <span class="user-name">Hiren
                        <strong>Ghadiya</strong>
                    </span>
                    <span class="user-role">Admin</span>
                    <span class="user-status">
                        <i class="fa fa-circle"></i>
                        <span>Online</span>
                    </span>
                </div>
            </div> -->
            <div class="sidebar-menu">
                <ul class="mt-3">
                    
                    <li class="sidebar-dropdown">
                        <a class="pointer" routerLink="/GhadiyaAdmin" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" >
                            <i class="fa fa-home mr-4"></i>
                            <span>Home</span>
                        </a>
                    </li>
                    <li class="sidebar-dropdown">
                        <a class="pointer" routerLink="business" routerLinkActive="active">
                            <i class="fa fa-product-hunt mr-4"></i>
                            <span>Business</span>
                        </a>
                    </li>
                    <li class="sidebar-dropdown">
                        <a class="pointer" routerLink="userRegister" routerLinkActive="active">
                            <i class="fa fa-users mr-4"></i>
                            <span>User Register</span>
                        </a>
                    </li>
                    <li class="sidebar-dropdown">
                        <a class="pointer" routerLink="highPosition" routerLinkActive="active">
                            <i class="fa fa-user-plus mr-4"></i>
                            <span>High Position</span>
                        </a>
                    </li>
                    <li class="sidebar-dropdown">
                        <a class="pointer" routerLink="changeVersion" routerLinkActive="active">
                            <i class="fa fa-viacoin mr-4"></i>
                            <span>Change Version</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- sidebar-menu  -->
        </div>
    </nav>

    <!-- page-content" -->

    <main class="page-content">
        <router-outlet></router-outlet>
    </main>
    <!-- page-content" -->
</div>
<!-- page-wrapper -->