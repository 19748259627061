import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/ghadiya-admin/services/auth.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {

  userForm: FormGroup;
  userDetail: any = [];
  Loading: boolean = false;
  villageDetail: any;
  status: any = "";
  villege: any = "";

  searchQsn: any;
  searchResult: any = [];
  constructor(private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService) {

    this.userForm = fb.group({
      _id: [''],
      firstName: [''],
      middleName: ['',Validators.required],
      lastName: [''],
      mobNumber: [''],
      village: [''],
      taluka: [''],
      district: [''],
      bloodGroup: [''],
      gender: [''],
      curCity: [''],
    
    });

  }

  ngOnInit(): void {
    this.getUserDetail();
    this.getVillageDetail();
  }

  mychange(event, type) {
    this.Loading = true;
    if (type == "status") {
      this.status = event.target.value;
    } else {
      this.villege = event.target.value;
    }
    let body = {
      'status': this.status,
      'village': this.villege
    }
    this.authService.filterUser(body).then((res) => {
      // console.log("res", res);
      // setTimeout(() => {
        this.Loading = false;
        this.userDetail = [];
        this.userDetail = res;
      // }, 1000);
     
    }, err => console.log("err", err));
  }


  onChange(ev, _id) {
    if (ev.target.checked) {
      this.authService.permisionUser({
        _id: _id,
        status: true
      }).then((res) => {
        // console.log("true",res);
        this.toastr.success('User Activate Successfull');
      }, err => console.log("err", err));
    } else {
      this.authService.permisionUser({
        _id: _id,
        status: false
      }).then((res) => {
        // console.log("false", res);

        this.toastr.success('User DeActivate Successfull');
      }, err => console.log("err", err));
    }
  }

  // search filter start

  serchQsnByName() {
    this.userDetail = this.searchResult;
    var val = this.searchQsn.toLowerCase();
    if (val && val.trim() != '') {
      this.userDetail = this.userDetail.filter((item) => {
        return (item.firstName.toLowerCase().indexOf(val) > -1) || 
          (item.middleName, item.lastName.toLowerCase().indexOf(val) > -1)
      })
    }
    else {
      this.userDetail = this.searchResult;
    }
  }
  // search filter End

  getUserDetail() {
    this.authService.getGhaUser().then(
      res => {
        // console.log("res",res)
        if (res['status']) {
          this.userDetail = res['res'];
          this.searchResult = this.userDetail;
        } else {
          console.log("status fales");
        }

      },
      err => {
        console.log(err);
      },
    );
  }

  getVillageDetail() {
    this.authService.getVillageUser().then(
      res => {
        if (res['status']) {
          this.villageDetail = res['res'];
          // console.log("getVillageUser", this.villageDetail)
        } else {
          console.log("status fales");
        }

      },
      err => {
        console.log(err);
      },
    );
  }

  submitData() {
    if (this.userForm.value._id) {
      this.authService.editGhaUser(this.userForm.value).then((res) => {
        this.getUserDetail();


        this.userForm.reset();
        this.toastr.success(res['msg']);
        $('#myModal').modal('hide');
      }, err => console.log("err", err));

    } else {
      if (this.userForm.valid) {
        this.authService.saveGhaUser(this.userForm.value).then((res) => {
          this.getUserDetail();
          this.userForm.reset();
          this.toastr.success(res['msg']);
          $('#myModal').modal('hide');
        }, err => console.log("err", err));    
      } else {
        this.authService.validationFire(this.userForm);
      }
    
    }

  }

  openModal(item) {
    if (item._id) {
      this.userForm.patchValue({
        _id: item._id,
        firstName: item.firstName,
        middleName: item.middleName,
        lastName: item.lastName,
        mobNumber: item.mobNumber,
        village: item.village,
        taluka: item.taluka,
        district: item.district,
        bloodGroup: item.bloodGroup,
        gender: item.gender,
        curCity: item.curCity,
       
      });

    } else {
      this.userForm.reset();
    }
  }

  DeleteData(_id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.authService.deleteGhaUser({ _id: _id }).then(
          (res: any) => {
            this.getUserDetail();
            this.userForm.reset();
            this.toastr.success(res['msg']);

            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )

          },
          err => {
            console.log(err);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })

  }

}
