import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/ghadiya-admin/services/auth.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
    selector: 'app-business',
    templateUrl: './business.component.html',
    styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {

    businessForm: FormGroup;
    businessData: any = [];
    cityFilterData: any;
    Loading: boolean = false;
    statusB: any = "";
    bussinessCity: any = "";

    searchQsn: any;
    searchResult: any = [];

    constructor(private authService: AuthService,
        private fb: FormBuilder,
        private toastr: ToastrService) {

        this.businessForm = fb.group({
            _id: [''],
            busName: [''],
            busType: [''],
            busOwner: [''],
            busContact: [''],
            busAddress: [''],
            busCity: [''],
            busCategory: ['',],
            busOther: ['',],
            busJob: ['',],
            busComment: ['',],
        });

    }

    ngOnInit(): void {
        this.getBusinessDetail();
        this.getCityFilterDetail(); 
    }

    onChange(ev, _id) {
        if (ev.target.checked) {
            this.authService.permissionBuss({
                _id: _id,
                status: true
            }).then((res) => {
                // console.log("true",res);
                this.toastr.success('User Activate Successfull');
            }, err => console.log("err", err));
        } else {
            this.authService.permissionBuss({
                _id: _id,
                status: false
            }).then((res) => {
                // console.log("false", res);

                this.toastr.success('User DeActivate Successfull');
            }, err => console.log("err", err));
        }
    }

    changeFn(event, type) {
        this.Loading = true;
        if (type == 'status') {
            this.statusB = event.target.value;
        } else {
            this.bussinessCity = event.target.value;
        }
        let body = {
            'status':this.statusB,
            'busCity':this.bussinessCity
        }
        // console.log("Bodyy", body);
        this.authService.getGhabusinessFilter(body).then((res) => {
            // console.log("res", res);
            setTimeout(() => {
                this.Loading = false;
                this.businessData = [];
                this.businessData = res;
            }, 1000);
        }, err => console.log("err", err));

    }


    serchQsnByName() {
        this.businessData = this.searchResult;
        var val = this.searchQsn.toLowerCase();
        if (val && val.trim() != '') {
            this.businessData = this.businessData.filter((item) => {
                return (item.busName.toLowerCase().indexOf(val) > -1)
            })
        }
        else {
            this.businessData = this.searchResult;
        }
    }


    getBusinessDetail() {
        this.authService.getBusiness().then(
            res => {
                // console.log("bussiness", res);
                if (res['status']) {
                    this.businessData = res['res'];
                    this.searchResult = this.businessData;
                } else {
                    console.log("status fales");
                }

            },
            err => {
                console.log(err);
            },
        );
    }

    getCityFilterDetail() {
        this.authService.getCityFilter().then(
            res => {
                // console.log("getCityFilter", res);
                if (res['status']) {
                    this.cityFilterData = res['res'];
                } else {
                    console.log("status fales");
                }

            },
            err => {
                console.log(err);
            },
        );
    }

    submitData() {
        if (this.businessForm.value._id) {
            this.authService.editBusiness(this.businessForm.value).then((res) => {
                this.getBusinessDetail();
                this.businessForm.reset();
                this.toastr.success(res['msg']);
                $('#myModal').modal('hide');
            }, err => console.log("err", err));

        } else {

            if (this.businessForm.valid) {
                this.authService.saveBusiness(this.businessForm.value).then((res) => {
                    this.getBusinessDetail();
                    this.businessForm.reset();
                    this.toastr.success(res['msg']);
                    $('#myModal').modal('hide');
                }, err => console.log("err", err));
            } else {
                this.authService.validationFire(this.businessForm);
            }

        }

    }

    openModal(item) {
        if (item._id) {
            this.businessForm.patchValue({
                _id: item._id,
                busName: item.busName,
                busType: item.busType,
                busOwner: item.busOwner,
                busContact: item.busContact,
                busAddress: item.busAddress,
                busCity: item.busCity,
                busCategory: item.busCategory,
                busOther: item.busOther,
                busJob: item.busJob,
                busComment: item.busComment,
            });

        } else {
            // console.log("Add");
            this.businessForm.reset();
        }
    }

    DeleteData(_id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.authService.deleteBusiness({ _id: _id }).then(
                    (res: any) => {
                        this.getBusinessDetail();
                        this.businessForm.reset();
                        this.toastr.success(res['msg']);

                        Swal.fire(
                            'Deleted!',
                            'Your imaginary file has been deleted.',
                            'success'
                        )
                    },
                    err => {
                        console.log(err);
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })

    }


}
