import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
   selector: '[numbersOnly]'
})

export class OnlynumberDirective {
   private editingAllowed: number[] = [
      8, // backspace
      37, // left arrow
      39, // right arrow
      46, // delete
   ]

   private allowed: number[] = [
      48, // 0
      49, // 1
      50, // 2
      51, // 3
      52, // 4
      53, // 5
      54, // 6
      55, // 7
      56, // 8
      57, // 9
      96, // 0 - keypad
      97, // 1 - keypad
      98, // 2 - keypad
      99, // 3 - keypad
      100, // 4 - keypad
      101, // 5 - keypad
      102, // 6 - keypad
      103, // 7 - keypad
      104, // 8 - keypad
      105, // 9 - keypad
   ];

   newVal: any

   constructor(public ngControl: NgControl) { }

   @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
      this.onInputChange(event, false);
   }

   onInputChange(event: any, backspace: any) {
      this.newVal = event.target?.value.replace(/\D/g, "");

      if (
         event.ctrlKey &&
         (event.keyCode == 86 || event.keyCode == 65 || event.keyCode == 88)
      ) {
         return;
      }

      if (this.editingAllowed.includes(event.keyCode) && !event.shiftKey) {
         return
      }

      if (this.allowed.includes(event.keyCode) && !event.shiftKey) {
         this.formatPhoneNumber(this.newVal);
      } else {
         return event.preventDefault();
      }
   }

   formatPhoneNumber(value: string) {
      if (!value) {
         this.ngControl.valueAccessor?.writeValue('');
      }

      let ret = ''
      const origLength = value.length

      for (let i = 0; i < value.length; ++i) {
         ret += value.charAt(i)
         // if ((ret.length == 3 && origLength > 3) || (ret.length == 7 && origLength > 7)) {
         //    ret += '-'
         // }
      }

      value = ret
      this.ngControl.valueAccessor?.writeValue(value);
   }
}


