import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';


import { GhadiyaAdminComponent } from './ghadiya-admin/ghadiya-admin.component';
import { BusinessComponent } from './ghadiya-admin/Business/business.component';
import { UserRegisterComponent } from './ghadiya-admin/user-register/user-register.component';
import { HighPositionComponent } from './ghadiya-admin/high-position/high-position.component';
import { ChangeVersionComponent } from './ghadiya-admin/change-version/change-version.component';
import { DashboardComponent } from './ghadiya-admin/dashboard/dashboard.component';
import { SidenavComponent } from './ghadiya-admin/sidenav/sidenav.component';
import { SharedModule } from './shared/shared/shared.module';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    GhadiyaAdminComponent,
    BusinessComponent,
    UserRegisterComponent,
    HighPositionComponent,
    ChangeVersionComponent,
    DashboardComponent,
    SidenavComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot(
      {
        timeOut: 10000,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
      }
    ),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
