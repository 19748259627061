import { Component, OnInit } from '@angular/core';

import { Location } from '@angular/common';
import { ResolveEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'khodiyar-estate';
 
  constructor(
  
    private location: Location,
    public router: Router
  ) {
   
   
    
  }

  ngOnInit() {
   
    //this.router = this.location.path();
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
       
      }
    })
   
  }
  
}
