import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $(".sidebar-dropdown > a").click(function () {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);
        $(this)
          .parent()
          .addClass("active");
      }
    });

    
    $("#show-sidebar").click(function () {
      $(".page-wrapper").addClass("toggled");
    });

    $("#close-sidebar").click(function () {
      $(".page-wrapper").removeClass("toggled");
    });
  
   

   
    if (window.innerWidth < 1200) {
      $(".page-wrapper").removeClass("toggled");
      $(".sidebar-dropdown").click(function () {
        $(".page-wrapper").removeClass("toggled");
      });

    }
  
  }


}
