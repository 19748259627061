<!-- Main content -->
<div class="mein-container" id="panel">
    <div class="header pb-6">
        <div class="container-fluid">
            <div class="header-body">
                <!-- Card stats -->
                <div class="border-bottom mb-3 mt-4">
                    <p class="h3 font-weight-bold ml-4">Ghadiya Parivar</p>
                </div>
                <div class="row" *ngIf="dashboardData">
                    <div class="col-xl-4 col-md-6">
                        <div class="card card-stats">
                            <!-- Card body -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="h3 font-weight-bold mb-0">Total Member</span>
                                        <h5 class="card-title text-uppercase text-muted mb-0">{{dashboardData.totUser}}
                                        </h5>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i class="fa fa-users text-dark" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6">
                        <div class="card card-stats">
                            <!-- Card body -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="h3 font-weight-bold mb-0">Total Village</span>
                                        <h5 class="card-title text-uppercase text-muted mb-0">
                                            {{dashboardData.totVillege}}</h5>
                                    </div>
                                    <div class="col-auto">
                                        <div
                                            class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                            <i class="fa fa-address-card text-dark" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6">
                        <div class="card card-stats">
                            <!-- Card body -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="h3 font-weight-bold mb-0">Total Business</span>
                                        <h5 class="card-title text-uppercase text-muted mb-0">
                                            {{dashboardData.totBusiness}}</h5>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                                            <i class="fa fa-building text-dark" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>