import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.navbar-nav>li>a').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });
  }

  homeSlider(){
    document.getElementById("homeSlider").scrollIntoView({behavior:"smooth"});
  }

  homeConsultant(){
    document.getElementById("homeConsultant").scrollIntoView({behavior:"smooth"});
  }

  

}
