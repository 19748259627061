import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlynumberDirective } from 'src/app/directives/number-only.directive'; 



@NgModule({
  declarations: [OnlynumberDirective],
  imports: [
    CommonModule
  ],
  exports:[OnlynumberDirective]
})
export class SharedModule { }
