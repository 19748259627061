import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/ghadiya-admin/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboardData: any;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.getDashboardData();
  }

  getDashboardData() {
    this.authService.getDashboard().then(
      res => {
        if (res['status']) {
          this.dashboardData = res;
          // console.log("dashboard", this.dashboardData)
        }
      }, err => {
        console.log("errr", err);
      })
  }

}
