import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessComponent } from './ghadiya-admin/Business/business.component';
import { ChangeVersionComponent } from './ghadiya-admin/change-version/change-version.component';
import { DashboardComponent } from './ghadiya-admin/dashboard/dashboard.component';
import { GhadiyaAdminComponent } from './ghadiya-admin/ghadiya-admin.component';
import { HighPositionComponent } from './ghadiya-admin/high-position/high-position.component';
import { SidenavComponent } from './ghadiya-admin/sidenav/sidenav.component';
import { UserRegisterComponent } from './ghadiya-admin/user-register/user-register.component';

const routes: Routes = [

  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'GhadiyaAdmin',
    component: GhadiyaAdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'business', component: BusinessComponent },
      { path: 'userRegister', component: UserRegisterComponent },
      { path: 'highPosition', component: HighPositionComponent },
      { path: 'changeVersion', component: ChangeVersionComponent },
    ]
  },
  { 
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  { 
    path: 'for-buy',
    loadChildren: () => import('./for-buy/for-buy.module').then(m => m.ForBuyModule)
  },
  { 
    path: 'for-rent',
    loadChildren: () => import('./for-rent/for-rent.module').then(m => m.ForRentModule)
  },
  { 
    path: 'for-buy-property/:category',
    loadChildren: () => import('./for-buy-property/for-buy-property.module').then(m => m.ForBuyPropertyModule)
  },
  { 
    path: 'for-rent-property/:category',
    loadChildren: () => import('./for-rent-property/for-rent-property.module').then(m => m.ForRentPropertyModule)
  },
  { 
    path: 'property-detail/:category',
    loadChildren: () => import('./property-detail/property-detail.module').then(m => m.PropertyDetailModule)
  },
  { 
    path: 'req-property-details/:category',
    loadChildren: () => import('./req-property-details/req-property-details.module').then(m => m.ReqPropertyDetailsModule)
  },
  { 
    path: 'khodiyarestate-555-myadmin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash : true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
